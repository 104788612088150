@use 'sass:map';
@use '@angular/material' as mat;
@use 'variables-dark' as dark;
@import "variables-light";

body{
  --primary: #{$primary};
  --primary-95: #D8F6FF;
  --onPrimary: #{$onPrimary};
  --primaryContainer: #{$primaryContainer};
  --onPrimaryContainer: #{$onPrimaryContainer};

  --secondary: #{$secondary};
  --onSecondary: #{$onSecondary};
  --secondaryContainer: #{$secondaryContainer};
  --onSecondaryContainer: #{$onSecondaryContainer};

  --tertiary: #{$tertiary};
  --onTertiary: #{$onTertiary};
  --tertiaryContainer: #{$tertiaryContainer};
  --onTertiaryContainer: #{$onTertiaryContainer};

  --error: #{$error};
  --onError: #{$onError};
  --errorContainer: #{$errorContainer};
  --onerrorContainer: #{$onerrorContainer};

  --background: #{$background};
  --onBackground: #{$onBackground};
  --surface: #{$surface};
  --onSurface: #{$onSurface};

  --outline: #{$outline};
  --onOutline: #{$onOutline};
  --onOutlineRgb: #{$onOutlineRgb};
  --surfaceVariant: #{$surfaceVariant};
  --onSurfaceVariant: #{$onSurfaceVariant};

  &.dark-theme {
    --primary: #{dark.$primary};
    --onPrimary: #{dark.$onPrimary};
    --primaryContainer: #{dark.$primaryContainer};
    --onPrimaryContainer: #{dark.$onPrimaryContainer};

    --secondary: #{dark.$secondary};
    --onSecondary: #{dark.$onSecondary};
    --secondaryContainer: #{dark.$secondaryContainer};
    --onSecondaryContainer: #{dark.$onSecondaryContainer};

    --tertiary: #{dark.$tertiary};
    --onTertiary: #{dark.$onTertiary};
    --tertiaryContainer: #{dark.$tertiaryContainer};
    --onTertiaryContainer: #{dark.$onTertiaryContainer};

    --error: #{dark.$error};
    --onError: #{dark.$onError};
    --errorContainer: #{dark.$errorContainer};
    --onerrorContainer: #{dark.$onerrorContainer};

    --background: #{dark.$background};
    --onBackground: #{dark.$onBackground};
    --surface: #{dark.$surface};
    --onSurface: #{dark.$onSurface};

    --outline: #{dark.$outline};
    --onOutline: #{$onOutline};
    --onOutlineRgb: #{$onOutlineRgb};
    --surfaceVariant: #{dark.$surfaceVariant};
    --onSurfaceVariant: #{dark.$onSurfaceVariant};
  }
}
