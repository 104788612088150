html {
  .head-type {
    font-family: 'HyundaiSansHead', 'HyundaiSansText', sans-serif;
    font-weight: 500;

    &-h1 {
      font-family: 'HyundaiSansHead', 'HyundaiSansText', sans-serif;
      font-size: 1.125rem;
      font-weight: 500;
    }

    &-h2 {
      font-family: 'HyundaiSansHead', 'HyundaiSansText', sans-serif;
      font-size: 1.5rem;
      font-weight: 500;
    }

    &-h3 {
      font-family: 'HyundaiSansHead', 'HyundaiSansText', sans-serif;
      font-size: 1rem;
      font-weight: 500;

      @media screen and (min-width: 599px) {
        font-size: 1.125rem;
      }
    }

    &-normal {
      font-family: 'HyundaiSansHead', 'HyundaiSansText', sans-serif;
      font-weight: normal;
      font-size: 0.875rem;
    }

    &-bold {
      font-family: 'HyundaiSansHead', 'HyundaiSansText', sans-serif;
      font-size: 0.875rem;
      font-weight: 500;

      @media screen and (min-width: 599px) {
        font-size: 1.125rem;
      }
    }

    &-thin {
      font-family: 'HyundaiSansHead', 'HyundaiSansText', sans-serif;
      font-weight: 300;
      font-size: 0.875rem;
    }
  }
}
