@font-face {
    font-family: HyundaiSansHead;
    font-weight: 400;
    font-style: normal;
    src: url("/assets/fonts/HyundaiSans/HyundaiSansHead-Regular.eot");
    src: url("/assets/fonts/HyundaiSans/HyundaiSansHead-Regular.eot?#iefix") format("embedded-opentype"),url("/assets/fonts/HyundaiSans/HyundaiSansHead-Regular.woff") format("woff"),url("/assets/fonts/HyundaiSans/HyundaiSansHead-Regular.ttf") format("truetype")
}

@font-face {
    font-family: HyundaiSansHead;
    font-weight: 300;
    font-style: normal;
    src: url("/assets/fonts/HyundaiSans/HyundaiSansHead-Light.eot");
    src: url("/assets/fonts/HyundaiSans/HyundaiSansHead-Light.eot?#iefix") format("embedded-opentype"),url("/assets/fonts/HyundaiSans/HyundaiSansHead-Light.woff") format("woff"),url("/assets/fonts/HyundaiSans/HyundaiSansHead-Light.ttf") format("truetype")
}

@font-face {
    font-family: HyundaiSansHead;
    font-weight: 500;
    font-style: normal;
    src: url("/assets/fonts/HyundaiSans/HyundaiSansHead-Medium.eot");
    src: url("/assets/fonts/HyundaiSans/HyundaiSansHead-Medium.eot?#iefix") format("embedded-opentype"),url("/assets/fonts/HyundaiSans/HyundaiSansHead-Medium.woff") format("woff"),url("/assets/fonts/HyundaiSans/HyundaiSansHead-Medium.ttf") format("truetype")
}

@font-face {
    font-family: HyundaiSansHead;
    font-weight: 700;
    font-style: normal;
    src: url("/assets/fonts/HyundaiSans/HyundaiSansHead-Bold.eot");
    src: url("/assets/fonts/HyundaiSans/HyundaiSansHead-Bold.eot?#iefix") format("embedded-opentype"),url("/assets/fonts/HyundaiSans/HyundaiSansHead-Bold.woff") format("woff"),url("/assets/fonts/HyundaiSans/HyundaiSansHead-Bold.ttf") format("truetype")
}

@font-face {
    font-family: HyundaiSansText;
    font-weight: 400;
    font-style: normal;
    src: url("/assets/fonts/HyundaiSans/HyundaiSansText-Regular.eot");
    src: url("/assets/fonts/HyundaiSans/HyundaiSansText-Regular.eot?#iefix") format("embedded-opentype"),url("/assets/fonts/HyundaiSans/HyundaiSansText-Regular.woff") format("woff"),url("/assets/fonts/HyundaiSans/HyundaiSansText-Regular.ttf") format("truetype")
}

@font-face {
    font-family: HyundaiSansText;
    font-weight: 500;
    font-style: normal;
    src: url("/assets/fonts/HyundaiSans/HyundaiSansText-Medium.eot");
    src: url("/assets/fonts/HyundaiSans/HyundaiSansText-Medium.eot?#iefix") format("embedded-opentype"),url("/assets/fonts/HyundaiSans/HyundaiSansText-Medium.woff") format("woff"),url("/assets/fonts/HyundaiSans/HyundaiSansText-Medium.ttf") format("truetype")
}

@font-face {
    font-family: HyundaiSansText;
    font-weight: 700;
    font-style: normal;
    src: url("/assets/fonts/HyundaiSans/HyundaiSansText-Bold.eot");
    src: url("/assets/fonts/HyundaiSans/HyundaiSansText-Bold.eot?#iefix") format("embedded-opentype"),url("/assets/fonts/HyundaiSans/HyundaiSansText-Bold.woff") format("woff"),url("/assets/fonts/HyundaiSans/HyundaiSansText-Bold.ttf") format("truetype")
}

// body {
//     font-family: 'Cera Pro Regular', sans-serif !important;
//     text-rendering: optimizeLegibility;
// }

body {
    font-family: 'HyundaiSansText', sans-serif !important;
    text-rendering: optimizeLegibility;
    font-weight: 400;
}

