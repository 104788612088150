body {
  .mat-card {
    &:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }
  }

  .mat-button {
    .mat-button-wrapper {
      svg {
        margin-right: 0.5rem;
      }

      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .mat-icon-button {
    border-radius: 25%;
    .mat-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-prefix {
    top: 0;
  }

  .normalize-dialog {
    height: 100%;

    .mat-dialog-container {
      padding: 0;
      overflow: hidden;
    }

    &-overflow {
      .mat-dialog-container {
        height: auto;
        padding: 0;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }

  // Переопределяем стили для степпера
  .stepper {
    .mat-horizontal-content-container {
      padding: 1.125rem;
      height: 100%;
      overflow: auto;
    }

    .mat-horizontal-stepper-header-container {
      max-width: 100%;
      margin: 0 auto 0;
    }

    .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
      top: 24px;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
      top: 24px;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
      padding: 12px;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label {
      display: none;
    }
  }

  .mat-spinner-invert {
    circle {
      stroke: var(--onPrimary);
    }
  }
}
.linear-disable {
  .mat-horizontal-stepper-header{
    pointer-events: none;
  }
}
.mat-horizontal-stepper-content {
  height: 100%;
}
